import React from "react";
import styled from "styled-components";
//
import Iframe from "react-iframe";
import { RichText } from "components/prismic";
//
import Support from "./support";
import Logo from "components/tampere.finland_rgb.png";
//
export default ({
  description,
  image,
  link,
  logo,
  support,
  support_text,
  title,
  ...rest
}) => (
  <Wrap>
    <img src={image.url} alt="takriko" />
    <Top>
      <img src={logo.url} alt="takriko" />
      <Title>{title}</Title>
      <Tampere>
        <img src={Logo} alt="Tampere" />
      </Tampere>
    </Top>
    <Bottom>
      <RichText {...description} />
      <Embed>
        <Iframe url={link.url} width="100%" height="100%" frameBorder="0" />
      </Embed>
      <RichText {...support_text} />
      <List>
        {support.map((support) => (
          <Support key={support.support_title} {...support} />
        ))}
      </List>
    </Bottom>
  </Wrap>
);
//
const Wrap = styled.div`
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
  }

  h4 {
    margin: 0;
    padding: 0 1rem;
    @media (min-width: 769px) {
      padding: 1rem 5rem 0;
    }
  }
`;
const Top = styled.div`
  background-color: #ffff;
  border-bottom: 1px solid #d9dce4;
  display: flex;
  justify-content: center;
  margin: 0 0.5rem;
  min-height: 3rem;
  min-height: calc(3rem + 2px);
  padding: 0.25rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  @media (min-width: 769px) {
    justify-content: flex-start;
    padding: 1.5rem 3.5rem;
    top: 0;
  }

  img {
    display: none;
    height: 100%;
    margin: 0.5rem;
    width: 100px;

    @media (min-width: 769px) {
      display: block;
      height: 5vw;
      object-fit: contain;
    }
  }
`;

const Bottom = styled.div`
  display: grid;
  row-gap: 1rem;
  padding: 1rem;

  @media (min-width: 769px) {
    padding: 1rem 5rem;
  }
`;
const Embed = styled.div`
  border: 1px solid #d9dce4;
  display: inline-block;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 75vh;
  height: calc(75vh - 65px);
`;
const Title = styled.h1`
  align-self: flex-end;
  font-size: 0.9em;
  justify-self: center;
  color: #213e65;
  margin: 0.5rem;

  @media (min-width: 500px) {
    font-size: 1.6em;
  }

  @media (min-width: 769px) {
    max-width: 30vw;
  }
`;

const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  list-style: none;
  margin: 0;
  padding: 0;

  @media (min-width: 769px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Tampere = styled.div`
  align-self: flex-end;
  right: 0;
  position: absolute;
  img {
    width: auto;
  }
`;
