import React from "react";
import styled from "styled-components";
//
export default ({ support_image, support_link, support_title, ...rest }) => (
  <Wrap>
    <a href={support_link.url} target="_blank" title={support_title}>
      <img src={support_image.url} alt={support_title} />
    </a>
    {/* <pre>
      <code>{JSON.stringify(rest, null, 2)}</code>
    </pre> */}
  </Wrap>
);
//
const Wrap = styled.li``;
